
export default {
  name: 'ScrollTopComponent',

  data() {
    return {
      scrollDistance: 600,
      visible: false,
      scrollTop: 0,
      timeout: 0
    };
  },

  watch: {
    scrollTop(val) {
      if (val > this.scrollDistance && this.visible === false) {
        this.visible = true;
      } else if (val <= this.scrollDistance && this.visible === true) {
        this.visible = false;
      }
    }
  },

  beforeMount() {
    window.addEventListener('scroll', this.scroll, false);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scroll, false);
  },

  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    scroll() {
      if (this.timeout) {
        window.cancelAnimationFrame(this.timeout);
      }

      this.timeout = window.requestAnimationFrame(() => {
        this.scrollTop = window.pageYOffset;
      });
    }
  }
};
