import { render, staticRenderFns } from "./index.vue?vue&type=template&id=04b03bfe"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediaPicture: require('/vercel/path0/components/elements/Media/Picture.vue').default,MediaVideo: require('/vercel/path0/components/elements/Media/Video.vue').default})
