import { render, staticRenderFns } from "./default.vue?vue&type=template&id=abb9e1a2"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageLoader: require('/vercel/path0/components/page/Loader.vue').default,PageScrollTop: require('/vercel/path0/components/page/ScrollTop.vue').default,PageCookie: require('/vercel/path0/components/page/Cookie.vue').default})
