
export default {
  props: {
    image: {
      type: [String, Object],
      default: null
    },
    alt: {
      type: String,
      default: ''
    },
    video: {
      type: [String, Object],
      default: null
    },
    sizes: {
      type: Object,
      default() {
        return {
          sm: { viewportWidth: 100, ratio: 'default' },
          md: { viewportWidth: 100, ratio: 'default' },
          lg: { viewportWidth: 100, ratio: 'default' },
          xl: { viewportWidth: 100, ratio: 'default' },
          '2xl': { viewportWidth: 100, ratio: 'default' }
        };
      }
    },
    aspectRatios: {
      type: Array,
      default() {
        return [
          { sm: 'default' },
          { md: 'default' },
          { lg: 'default' },
          { xl: 'default' },
          { '2xl': 'default' },
          { '3xl': 'default' }
        ];
      }
    }
  }
};
