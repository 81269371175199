
import { mapGetters } from 'vuex';

// @group page
export default {
  computed: {
    ...mapGetters({
      isLoaderActive: 'ui/isLoaderActive'
    })
  },
  watch: {
    isLoaderActive() {
      document.documentElement.classList[this.isLoaderActive ? 'add' : 'remove']('no-scroll');
    }
  }
};
