export const SanityContent = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-content.js' /* webpackChunkName: "components/sanity-content" */).then(c => wrapFunctional(c.default || c))
export const SanityFile = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-file.js' /* webpackChunkName: "components/sanity-file" */).then(c => wrapFunctional(c.default || c))
export const SanityImage = () => import('../../node_modules/@nuxtjs/sanity/dist/components/sanity-image.js' /* webpackChunkName: "components/sanity-image" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFadeIn = () => import('../../components/elements/Animations/CustomFadeIn.vue' /* webpackChunkName: "components/animation-custom-fade-in" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFadeIn2 = () => import('../../components/elements/Animations/CustomFadeIn2.vue' /* webpackChunkName: "components/animation-custom-fade-in2" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFadeIn3 = () => import('../../components/elements/Animations/CustomFadeIn3.vue' /* webpackChunkName: "components/animation-custom-fade-in3" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFadeIn4 = () => import('../../components/elements/Animations/CustomFadeIn4.vue' /* webpackChunkName: "components/animation-custom-fade-in4" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFadeIn5 = () => import('../../components/elements/Animations/CustomFadeIn5.vue' /* webpackChunkName: "components/animation-custom-fade-in5" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFadeIndelay = () => import('../../components/elements/Animations/CustomFadeIndelay.vue' /* webpackChunkName: "components/animation-custom-fade-indelay" */).then(c => wrapFunctional(c.default || c))
export const AnimationCustomFlagIn = () => import('../../components/elements/Animations/CustomFlagIn.vue' /* webpackChunkName: "components/animation-custom-flag-in" */).then(c => wrapFunctional(c.default || c))
export const AnimationGsapFadeInExample = () => import('../../components/elements/Animations/GsapFadeIn_Example.vue' /* webpackChunkName: "components/animation-gsap-fade-in-example" */).then(c => wrapFunctional(c.default || c))
export const DocumentErrorPage = () => import('../../components/document/ErrorPage.vue' /* webpackChunkName: "components/document-error-page" */).then(c => wrapFunctional(c.default || c))
export const DocumentPage = () => import('../../components/document/Page.vue' /* webpackChunkName: "components/document-page" */).then(c => wrapFunctional(c.default || c))
export const PageCookie = () => import('../../components/page/Cookie.vue' /* webpackChunkName: "components/page-cookie" */).then(c => wrapFunctional(c.default || c))
export const PageFooter = () => import('../../components/page/Footer.vue' /* webpackChunkName: "components/page-footer" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/page/Header.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageLoader = () => import('../../components/page/Loader.vue' /* webpackChunkName: "components/page-loader" */).then(c => wrapFunctional(c.default || c))
export const PageScrollTop = () => import('../../components/page/ScrollTop.vue' /* webpackChunkName: "components/page-scroll-top" */).then(c => wrapFunctional(c.default || c))
export const ContentFileDownload = () => import('../../components/content/FileDownload.vue' /* webpackChunkName: "components/content-file-download" */).then(c => wrapFunctional(c.default || c))
export const ContentHero = () => import('../../components/content/Hero.vue' /* webpackChunkName: "components/content-hero" */).then(c => wrapFunctional(c.default || c))
export const ContentImageTextRow = () => import('../../components/content/ImageTextRow.vue' /* webpackChunkName: "components/content-image-text-row" */).then(c => wrapFunctional(c.default || c))
export const ContentInfoBox = () => import('../../components/content/InfoBox.vue' /* webpackChunkName: "components/content-info-box" */).then(c => wrapFunctional(c.default || c))
export const ContentLinkList = () => import('../../components/content/LinkList.vue' /* webpackChunkName: "components/content-link-list" */).then(c => wrapFunctional(c.default || c))
export const ContentMultimedia = () => import('../../components/content/Multimedia.vue' /* webpackChunkName: "components/content-multimedia" */).then(c => wrapFunctional(c.default || c))
export const ContentPostGrid = () => import('../../components/content/PostGrid.vue' /* webpackChunkName: "components/content-post-grid" */).then(c => wrapFunctional(c.default || c))
export const ContentQuiz = () => import('../../components/content/Quiz.vue' /* webpackChunkName: "components/content-quiz" */).then(c => wrapFunctional(c.default || c))
export const ContentSectionHeader = () => import('../../components/content/SectionHeader.vue' /* webpackChunkName: "components/content-section-header" */).then(c => wrapFunctional(c.default || c))
export const ContentSwiper = () => import('../../components/content/Swiper.vue' /* webpackChunkName: "components/content-swiper" */).then(c => wrapFunctional(c.default || c))
export const ContentTextBlock = () => import('../../components/content/TextBlock.vue' /* webpackChunkName: "components/content-text-block" */).then(c => wrapFunctional(c.default || c))
export const ContentYoutubeVideo = () => import('../../components/content/YoutubeVideo.vue' /* webpackChunkName: "components/content-youtube-video" */).then(c => wrapFunctional(c.default || c))
export const ContentFormHoneypotForm = () => import('../../components/content/Form/HoneypotForm.vue' /* webpackChunkName: "components/content-form-honeypot-form" */).then(c => wrapFunctional(c.default || c))
export const ContentForm = () => import('../../components/content/Form/index.vue' /* webpackChunkName: "components/content-form" */).then(c => wrapFunctional(c.default || c))
export const ArticleInfos = () => import('../../components/elements/ArticleInfos.vue' /* webpackChunkName: "components/article-infos" */).then(c => wrapFunctional(c.default || c))
export const Back = () => import('../../components/elements/Back.vue' /* webpackChunkName: "components/back" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/elements/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Cta = () => import('../../components/elements/Cta.vue' /* webpackChunkName: "components/cta" */).then(c => wrapFunctional(c.default || c))
export const DetailPageHero = () => import('../../components/elements/DetailPageHero.vue' /* webpackChunkName: "components/detail-page-hero" */).then(c => wrapFunctional(c.default || c))
export const FieldDatePicker = () => import('../../components/elements/FieldDatePicker.vue' /* webpackChunkName: "components/field-date-picker" */).then(c => wrapFunctional(c.default || c))
export const FieldPhoneNumber = () => import('../../components/elements/FieldPhoneNumber.vue' /* webpackChunkName: "components/field-phone-number" */).then(c => wrapFunctional(c.default || c))
export const Heading = () => import('../../components/elements/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitch = () => import('../../components/elements/LanguageSwitch.vue' /* webpackChunkName: "components/language-switch" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../components/elements/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const MarkAsClapped = () => import('../../components/elements/MarkAsClapped.vue' /* webpackChunkName: "components/mark-as-clapped" */).then(c => wrapFunctional(c.default || c))
export const MarkAsFavourite = () => import('../../components/elements/MarkAsFavourite.vue' /* webpackChunkName: "components/mark-as-favourite" */).then(c => wrapFunctional(c.default || c))
export const MenuOverlay = () => import('../../components/elements/MenuOverlay.vue' /* webpackChunkName: "components/menu-overlay" */).then(c => wrapFunctional(c.default || c))
export const MyFavoritesBtn = () => import('../../components/elements/MyFavoritesBtn.vue' /* webpackChunkName: "components/my-favorites-btn" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/elements/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const NavigationTree = () => import('../../components/elements/NavigationTree.vue' /* webpackChunkName: "components/navigation-tree" */).then(c => wrapFunctional(c.default || c))
export const Post = () => import('../../components/elements/Post.vue' /* webpackChunkName: "components/post" */).then(c => wrapFunctional(c.default || c))
export const RelatedPost = () => import('../../components/elements/RelatedPost.vue' /* webpackChunkName: "components/related-post" */).then(c => wrapFunctional(c.default || c))
export const Richtext = () => import('../../components/elements/Richtext.vue' /* webpackChunkName: "components/richtext" */).then(c => wrapFunctional(c.default || c))
export const Section = () => import('../../components/elements/Section.vue' /* webpackChunkName: "components/section" */).then(c => wrapFunctional(c.default || c))
export const SocialMediaBar = () => import('../../components/elements/SocialMediaBar.vue' /* webpackChunkName: "components/social-media-bar" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/elements/AccordionGroup/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AccordionGroup = () => import('../../components/elements/AccordionGroup/index.vue' /* webpackChunkName: "components/accordion-group" */).then(c => wrapFunctional(c.default || c))
export const MediaPicture = () => import('../../components/elements/Media/Picture.vue' /* webpackChunkName: "components/media-picture" */).then(c => wrapFunctional(c.default || c))
export const MediaVideo = () => import('../../components/elements/Media/Video.vue' /* webpackChunkName: "components/media-video" */).then(c => wrapFunctional(c.default || c))
export const Media = () => import('../../components/elements/Media/index.vue' /* webpackChunkName: "components/media" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
