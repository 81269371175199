const sanityClient = require('@sanity/client');

export default async function ({ route, isHMR, $config, store }) {
  if (isHMR) { return; }
  const {
    query: { preview, documentId }
  } = route;

  if (!preview) {
    store.commit('preview/setPreviewMode', false);
    return;
  }

  const client = sanityClient({
    projectId: $config.api.projectId,
    dataset: $config.api.dataset,
    apiVersion: $config.api.apiVersion,
    useCdn: false,
    token:
      // eslint-disable-next-line
      'skMJqpy2msskOaotTBmT115gke4cPbIpBSMuI7oZnsvLw4h4ap1jjke3fxxg6rkXEmy1MFMMTxRPdi9lKc6VKYpsL3ZMUY9xwZyIBewLuiWX7004FNW37ltL20tPtJ2GrRzvVFtcVarfa34zNLhkJIMW8h6YM9Sgpmx9JMimwHyiXRmPdyhu'
  });

  const query = `*[_id=="${documentId}"][0] { ..., postCategories[]->}`;
  const result = await client.fetch(query);
  store.commit('preview/setPreviewMode', true);
  store.commit('preview/setDocument', result || {});
  store.commit('preview/setDocument', result || {});
}
