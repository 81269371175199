/**
 * This state will hold the
 * translation of each slugs for the current page
 */
export const state = () => ({
  translations: {},
  blogPostId: '',
  isBlogPage: false,
  categories: []
});

export const mutations = {
  setTranslations(state, value) {
    state.translations = value;
  },
  setBlogPostId(state, value) {
    state.blogPostId = value;
  },
  setIsBlogPage(state, value) {
    state.isBlogPage = value;
  },
  setCategories(state, value) {
    state.categories = value;
  }
};

export const getters = {
  getTranslations(state) {
    return state.translations;
  },
  getBlogPostId(state) {
    return state.blogPostId;
  },
  getIsBlogPage(state) {
    return state.isBlogPage;
  },
  getCategories(state) {
    return state.categories;
  }
};
