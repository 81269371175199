export const state = () => ({
  isOverlayActive: false,
  isLoaderActive: false,
  isFavoriteFilterActivated: false
});

export const mutations = {
  toggleOverlay(state, value) {
    state.isOverlayActive = value;
  },
  toggleLoader(state, value) {
    state.isLoaderActive = value;
  },
  favoriteFilterActivated(state, value) {
    state.isFavoriteFilterActivated = value;
  }
};

export const getters = {
  isOverlayActive(state) {
    return state.isOverlayActive;
  },
  isLoaderActive(state) {
    return state.isLoaderActive;
  },
  isFavoriteFilterActivated(state) {
    return state.isFavoriteFilterActivated;
  }
};
