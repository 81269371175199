
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      locale: 'navigation/locale'
    })
  }
};
