import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=056d3d4b"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=056d3d4b&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Media: require('/vercel/path0/components/elements/Media/index.vue').default,Richtext: require('/vercel/path0/components/elements/Richtext.vue').default,LanguageSwitch: require('/vercel/path0/components/elements/LanguageSwitch.vue').default})
