import { groq } from '@nuxtjs/sanity';

/**
 * With this query, we get all the content from the Sanity -> Settings
 */

export default function getSiteSettingsQuery(locale) {
  return groq`*[_type=="footer" && __i18n_lang=="${locale}_CH"][0] {
    "footer": @,
    "postCount": count(*[_type == "blog" && __i18n_lang=="${locale}_CH"]),
    "categories": *[_type=="category" &&
      __i18n_lang=="${locale}_CH" &&
      !(_id in path("drafts.**"))] { entry, _id },
    "sitemap": *[
      (_type=="page" || _type=="blog") &&
      __i18n_lang=="${locale}_CH" &&
      !(_id in path("drafts.**"))
    ] { url, _id, __i18n_lang },
    "legal": *[
      _type=="legal" &&
      __i18n_lang=="${locale}_CH"
    ][0] { conditions->{url, title}, imprint->{url, title}, privacy->{url, title} },
    "staticText": *[
      _type=="staticText" &&
      __i18n_lang=="${locale}_CH"
    ] { entry, _id }
  }`;
}
