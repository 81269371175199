
import { getCookie, setCookie } from 'tiny-cookie';

const STORAGE_TYPES = {
  local: 'localStorage',
  cookies: 'cookies'
};

export default {
  name: 'CookieLawComponent',
  props: {
    buttonDecline: {
      type: Boolean,
      default: false
    },
    /**
     * Cookie Container position
     * tl = topleft, tr = topright, bl = bottomleft, br = bottomright
     * @type {Object}
     */
    position: {
      type: String,
      default: 'bl'
    },
    /**
     * Transition style
     * fd = fade, ft = fromTop, fl = fromLeft, fb = fromBottom, fr = fromRight
     * @type {Object}
     */
    transition: {
      type: String,
      default: 'fd'
    },
    storageName: {
      type: String,
      default: 'cookie:accepted'
    },
    storageType: {
      type: String,
      default: STORAGE_TYPES.local
    },
    cookieOptions: {
      type: Object,
      default: () => {},
      required: false
    }
  },
  data() {
    return {
      supportsLocalStorage: true,
      isOpen: false
    };
  },
  computed: {
    containerPosition() {
      return `position-${this.position}`;
    },

    containerTransition() {
      return `transition-${this.transition}`;
    },

    canUseLocalStorage() {
      return this.storageType === STORAGE_TYPES.local && this.supportsLocalStorage;
    }
  },

  created() {
    if (process.client) {
      const cookie = getCookie(this.storageName);
      this.isOpen = cookie === null;
    }
  },

  methods: {
    onAccept() {
      setCookie(this.storageName, true, { ...this.cookieOptions, expires: '1Y' });
      this.isOpen = false;
    },

    onDecline() {
      setCookie(this.storageName, false, { ...this.cookieOptions, expires: '1Y' });
      this.isOpen = false;
    }
  }
};
