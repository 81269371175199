export const state = () => ({
  document: undefined,
  isPreviewMode: false
});

export const mutations = {
  setPreviewMode(state, isPreviewMode) {
    state.isPreviewMode = isPreviewMode;
  },
  setDocument(state, document) {
    state.document = document;
  }
};

export const getters = {
  isPreviewMode(state) {
    return state.isPreviewMode;
  },
  document(state) {
    return state.document;
  }
};
