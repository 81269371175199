
// @group document
export default {
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      pageError: {
        statusCode: this.error?.statusCode || 400,
        message: this.error?.message || 'It looks like we have some problem, Huston.'
      }
    };
  }
};
