export default {
  methods: {
    getMultilanguageLinkTags(pageHref, translations) {
      if (!translations) {
        return;
      }

      const baseUrl = this.$config.baseURL;
      const appLocales = this.$i18n.locales;
      const defaultLocale = this.$i18n.defaultLocale;

      // Note: There might be the case where pages are not translated.
      // So the backend response will set for ex: { de: {...}, fr: NULL}
      const availableTranslations = appLocales.filter(
        (locale) => locale.code !== translations[locale.code]
      );

      const links = [];
      availableTranslations.forEach((locale) => {
        const { url, home: isHome } = translations[locale.code] || '';
        const fullPath = url?.fullPath || '';
        const isDefaultLocale = locale.code === defaultLocale;
        // Note: if translation don't exist, then we avoid to have URLs like
        // https://localhost/undefined

        let href;
        // Business Rule: base locale don't have prefix. Ex: http://localhost/locale
        if (isDefaultLocale) {
          href = isHome ? `${baseUrl}` : `${baseUrl}/${fullPath}`;
          links.push({ rel: 'alternate', hreflang: locale.iso, href });
          links.push({ rel: 'alternate', hreflang: 'x-default', href });
        } else {
          href = isHome ? `${baseUrl}/${locale.code}` : `${baseUrl}/${locale.code}/${fullPath}`;
          links.push({ rel: 'alternate', hreflang: locale.iso, href });
        }
      });

      links.push({ rel: 'canonical', href: pageHref });

      return links;
    }
  }
};
