module.exports = {
  title: 'Umwelt Schweiz',
  description: 'Umwelt Schweiz',
  debugMode: false,
  // be sure to not add any / at the end of URL
  url: {
    dev: 'http://localhost:3000',
    prod: 'https://umwelt-schweiz.ch'
  },
  api: {
    projectId: 'r4pa3hao',
    dataset: 'production',
    // Add current date to fix the data api version. This avoids breaking changes over the time.
    apiVersion: '2021-10-11',
    useCdn: true
  },
  modules: {
    // TODO: Webfontloader: https://github.com/Developmint/nuxt-webfontloader
    googleTagManager: {
      active: false,
      id: 'GTM-XXXXXXX'
    },
    googleAnalytics: {
      active: false,
      id: 'UA-XXXXXX-X'
    },
    sendgrid: {
      active: true,
      id: process.env.SENDGRID_ID
    },
    cookieConsent: {
      active: true,
      position: 'bl', // tl = topleft, tr = topright, bl = bottomleft, br = bottomright
      transition: 'fl' // fd = fade, ft = fromTop, fl = fromLeft, fb = fromBottom, fr = fromRight
    }
  },
  mixins: {
    setDevmode: true,
    setViewportSizes: false
  },
  i18n: {
    baseUrl:
      process.env.NODE_ENV === 'production'
        ? 'https://umwelt-schweiz.ch/'
        : 'http://localhost:3333/',
    strategy: 'prefix_except_default',
    langDir: '~/locales/',
    vuex: {
      moduleName: 'i18n',
      syncRouteParams: true
    },
    locales: [
      {
        code: 'de',
        iso: 'de_CH',
        file: 'de.json'
      },
      {
        code: 'fr',
        iso: 'fr_CH',
        file: 'fr.json'
      },
      {
        code: 'it',
        iso: 'it_CH',
        file: 'it.json'
      }
    ],
    defaultLocale: 'de',
    detectBrowserLanguage: {
      useCookie: true,
      cookieKey: 'i18n_redirected',
      fallbackLocale: 'de',
      redirectOn: 'root'
    }
  }
};
