import getSiteSettingsQuery from '~/queries/getSiteSettingsQuery';

export const state = () => ({
  footer: [],
  legal: [],
  socialMedia: [],
  staticText: {},
  sitemap: [],
  postCount: 0,
  categories: []
});

export const mutations = {
  setFooter(state, footer) {
    state.footer = footer;
  },
  setStaticText(state, staticText) {
    state.staticText = staticText;
  },
  setSitemap(state, sitemap) {
    state.sitemap = sitemap;
  },
  setLegal(state, legal) {
    state.legal = legal;
  },
  setSocialMedia(state, socialMedia) {
    state.socialMedia = socialMedia;
  },
  setPostCount(state, postCount) {
    state.postCount = postCount;
  },
  setCategories(state, categories) {
    state.categories = categories;
  }
};

export const getters = {
  footer(state) {
    return state.footer;
  },
  staticText(state) {
    return state.staticText;
  },
  sitemap(state) {
    return state.sitemap;
  },
  legal(state) {
    return state.legal;
  },
  socialMedia(state) {
    return state.socialMedia;
  },
  getStaticTextByKey: (state) => (key) => {
    return state.staticText.find(({ entry }) => entry.key === key);
  },
  getSlugByRef: (state) => (_ref) => {
    return state.sitemap.find((page) => page._id === _ref);
  },
  postCount(state) {
    return state.postCount;
  },
  categories(state) {
    return state.categories;
  }
};

export const actions = {
  async load({ commit, rootState }) {
    const locale = rootState.navigation.locale;
    const query = getSiteSettingsQuery(locale);
    const result = await this.$sanity.fetch(query);

    commit('setFooter', result?.footer ?? []);
    commit('setSitemap', result?.sitemap ?? []);
    commit('setStaticText', result?.staticText ?? []);
    commit('setLegal', result?.legal ?? []);
    commit('setSocialMedia', result?.socialMedia ?? []);
    commit('setPostCount', result?.postCount ?? []);
    commit('setCategories', result?.categories ?? []);
  }
};
