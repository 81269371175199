
import imageUrlBuilder from '@sanity/image-url';
import resolveConfig from 'tailwindcss/resolveConfig';
import getImageDatasFromRef from '../../../queries/getImageDatasFromRef';
import tailwindConfig from '../../../tailwind.config';

export default {
  props: {
    image: {
      type: Object,
      default() {
        return {};
      }
    },
    alt: {
      type: String,
      default: ''
    },
    aspectRatios: {
      type: Array,
      default() {
        return [
          { sm: 'default' },
          { md: 'default' },
          { lg: 'default' },
          { xl: 'default' },
          { '2xl': 'default' },
          { '3xl': 'default' }
        ];
      }
    }
  },
  data() {
    return {
      device: '',
      width: 0,
      height: 0,
      breakpoints: [],
      sanityImage: {
        asset: null,
        hotspot: null,
        crop: null,
        alt: null,
        width: null,
        height: null
      }
    };
  },
  // head() {
  //   if (!this.sanityImage.asset) {
  //     return;
  //   }

  //   return {
  //     link: [
  //       {
  //         rel: 'prefetch',
  //         as: 'image',
  //         href: this.src
  //       }
  //     ]
  //   };
  // },

  computed: {
    srcCached() {
      const image = {
        asset: this.image?.metadata?.asset,
        crop: this.image?.metadata?.crop,
        hotspot: this.image?.metadata?.hotspot
      };

      const { width } = image?.asset?.metadata?.dimensions || 'default';
      const height = this.getAspectRatioHeight(width, 'default');

      return this.createUrl(image.asset, width, height);
    },
    cachedSources() {
      const {
        theme: { screens }
      } = resolveConfig(tailwindConfig);
      const image = {
        asset: this.image?.metadata?.asset,
        crop: this.image?.metadata?.crop,
        hotspot: this.image?.metadata?.hotspot
      };
      const arSources = this.aspectRatios.map((ar) => {
        let height;
        const screen = Object.keys(ar)[0];
        const aspectRatio = ar[screen];
        const mediaQuery = screens[screen];
        const { width } = image?.asset?.metadata?.dimensions || 'auto';

        if (aspectRatio === 'default') {
          height = image.asset?.metadata?.dimensions?.height;
        } else {
          height = this.getAspectRatioHeight(width, aspectRatio);
        }

        const url = this.createUrl(image.asset, width, height);
        return {
          width,
          height,
          url,
          mediaQuery,
          screen
        };
      });

      return arSources.reverse();
    },
    src() {
      return this.createBuilder()
        .image(this.sanityImage.asset)
        .width(this.sanityImage.width)
        .height(this.sanityImage.height)
        .dpr(1)
        .auto('format')
        .url();
    },
    // More info: https://www.sanity.io/docs/presenting-images#QsZtvbLC
    sources() {
      return this.generateUrlPerEachBreakpoint();
    },
    loadingAttribute() {
      return this.image.isLazyloaded ? 'lazy' : 'eager';
    }
  },

  async created() {
    if (this.image?.refImage) {
      const imageRef = this.image.refImage?._ref;
      const query = getImageDatasFromRef(imageRef);
      const sanityImage = await this.$sanity.fetch(query);
      const { asset, crop, hotspot } = sanityImage;

      this.sanityImage.asset = {
        ...asset,
        crop,
        hotspot
      };
      this.sanityImage.crop = crop;
      this.sanityImage.hotspot = hotspot;
      this.sanityImage.width = asset?.metadata?.dimensions?.width;
      this.sanityImage.height = asset?.metadata?.dimensions?.height;

      this.breakpoints = this.calculateDimensionsForEachScreen().reverse();
    }
  },

  mounted() {
    // Note: we reverse this array to make the <source> media query work
    this.breakpoints = this.calculateDimensionsForEachScreen().reverse();
    // We calculate width and height of the <img /> tag
  },

  methods: {
    createBuilder() {
      return imageUrlBuilder(this.$config.api);
    },
    // This function is used to define the aspect ratio of the image according
    // to the parameters we defined when calling this component.
    getAspectRatioHeight(width, ratio) {
      if (ratio === 'square') {
        return width;
      }

      if (ratio === 'panorama') {
        return Math.round((width / 147) * 36.75);
      }

      if (ratio.includes(':')) {
        const dimension = ratio.split(':');
        return Math.round((width / parseFloat(dimension[0])) * parseFloat(dimension[1]));
      }

      // if default, then we return the natural height of the image
      return this.height;
    },
    // calculate aspect ratio of given breakpoint
    calculateDimensionsForEachScreen() {
      const {
        theme: { screens }
      } = resolveConfig(tailwindConfig);

      const dimensions = this.aspectRatios.map((entry) => {
        const screen = Object.keys(entry);
        const aspectRatio = entry[screen];
        const mediaQuery = screens[screen];
        let width, height;

        if (aspectRatio === 'default') {
          width = this.sanityImage.width;
          height = this.sanityImage.height;
        } else {
          width = parseInt(mediaQuery.replace('px', ''));
          height = this.getAspectRatioHeight(width, aspectRatio);
        }

        return {
          [screen]: {
            mediaQuery,
            aspectRatio,
            width,
            height
          }
        };
      });

      return dimensions;
    },
    generateUrlPerEachBreakpoint() {
      return this.breakpoints.map((breakpoint) => {
        const screen = Object.keys(breakpoint);
        const imageData = breakpoint[screen];

        const url = this.createBuilder()
          .image(this.sanityImage.asset)
          .width(imageData.width)
          .height(imageData.height)
          .auto('format')
          .dpr(1)
          .fit('crop')
          .url();

        return {
          screen: screen[0],
          url,
          ...imageData
        };
      });
    },
    createUrl(asset, width, height) {
      return this.createBuilder()
        .image(asset)
        .width(width)
        .height(height)
        .dpr(1)
        .auto('format')
        .url();
    }
  }
};
