export default {
  methods: {
    getPageSchema(pageHref, title, description, type = 'WebPage') {
      return [
        { '@context': 'http://schema.org' },
        { '@type': type },
        { name: title },
        { description },
        { url: pageHref }
      ];
    }
  }
};
