export default {
  methods: {
    $convertDateToString(date, locale) {
      const toParse = new Date(date);
      const day = toParse.getDate();
      const month = toParse.toLocaleString(locale.replace('_CH', ''), { month: 'long' });
      const year = toParse.getFullYear();
      return `${day}. ${this.capitalizeFirstLetter(month)} ${year}`;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
};
