
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderComponent',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    isBlogPage: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    ...mapGetters({
      isOverlayActive: 'ui/isOverlayActive'
    })
  },

  methods: {
    toggleNavOverlay() {
      this.$store.commit('ui/toggleOverlay', !this.isOverlayActive);
    }
  }
};
