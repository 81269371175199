export default function () {
  if (!process.client) {
    return;
  }
  /* eslint-disable */
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function () {
    var u = "https://umweltschweiz.matomo.cloud/";
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '1']);
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.async = true; g.src = '//cdn.matomo.cloud/umweltschweiz.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g, s);
  })();
}